import {useState, useEffect} from 'react';
import './App.css';
import logo from './kearneyonlineLogo.png';

// Import icons
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CodeIcon from '@mui/icons-material/Code';
import MailIcon from '@mui/icons-material/Mail';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PublicIcon from '@mui/icons-material/Public';
import TwitterIcon from '@mui/icons-material/Twitter';

function App() {

  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    }
  }, []);


  return (
    <div className="App">
      <div className='header'>
        <div className='date-time'>
          <div className='logo-container'>
            <img src={logo} alt='logo' className='logo'/>
            kearneyonline
          </div>
          <div className='time'>
            {time.toLocaleTimeString()}
          </div>
        </div>
      </div>

      <div className='body'>

        <div className='body-icons'>
          <a href='https://github.com/johnkearney1'>
            <GitHubIcon fontSize='medium'/>
          </a>  
          <a href='https://soundcloud.com/kearneyjohn'>
            <MusicNoteIcon fontSize='medium'/>
          </a>
          <a href='mailto:john@kearneyjohn.com'>
            <MailIcon fontSize='medium'/>
          </a>
          <a href='https://www.instagram.com/kearneyonline/'>
            <InstagramIcon fontSize='medium'/>
          </a>
          <a href='https://twitter.com/joh5'>
            <TwitterIcon fontSize='medium'/>
          </a>
          
        </div>

        <div className='body-section'
          onClick={() => window.location.href='https://kearneyjohn.com/'}
        >
          <PublicIcon fontSize='large'/>
          <div className='body-content'>
            <h1>Portfolio →</h1>
            2023 Portfolio Showcase - KearneyJohn.com
          </div>
        </div>

        <div className='body-section disabled'
          onClick={() => window.location.href='https://shop.kearneyjohn.com/'}
        >
          <MusicNoteIcon fontSize='large'/>
          <div className='body-content'>
            <h1>Samples →</h1>
            Browse the library of Kearneyonline samples and kits
          </div>
        </div>

        

        <div className='body-section disabled'
          onClick={() => window.location.href='https://tools.kearneyjohn.com/'}
        >
          <AutoFixHighIcon fontSize='large'/>
          <div className='body-content'>
            <h1>Tools →</h1>
            Automation tools for musicians
          </div>
        </div>

        <div className='body-section disabled'
          onClick={() => window.location.href='https://dev.kearneyjohn.com/'}
        >
          <CodeIcon fontSize='large'/>
          <div className='body-content'>
            <h1>Development →</h1>
            Design forward web development and consultation for musicians
          </div>
        </div>

        

      </div>

      <div className='footer'>
        <div>
          <a href='https://www.kearneyjohn.com/'
            className='link'
          >
            [ © Kearney Labs - 2023 ]
          </a>

          <a href='https://shop.kearneyjohn.com/privacy-policy/'
            className='link'
          >
            [ ✽ Privacy ]
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
